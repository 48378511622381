<template>
    <div class="card card-height">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Invoice Number</th>
                        <th v-if="false">Invoice Type</th>
                        <th v-if="false">Order Type</th>
                        <th v-if="contactName">Client Name</th>
                        <th v-if="hasBusinessName">Business Unit</th>
                        <th v-if="salePersonName">Sales Person</th>
                        <th>Invoice Amount</th>
                        <th>VAT Amount</th>
                        <th>Total Amount</th>
                        <th>Discount Amount</th>
                        <th>Paid Amount</th>
                        <th>Due Amount</th>
                        <th>COGS</th>
                        <th>Gross Profit</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(item, i) in tableItems" :key="i">
                        <td>{{ item.date }} </td>
                        <td>{{ item.bill_number }}</td>
                        <td v-if="false">{{ item.invoice_type }}</td>
                        <td v-if="false">{{ item.sales_order_master ? item.sales_order_master.sale_type_title : '' }}</td>
                        <td v-if="contactName">{{ item.contact_name }}</td>
                        <td v-if="hasBusinessName">{{ item.business_name }}</td>
                        <td v-if="salePersonName">{{ item.sr_name }}</td>
                        <td>{{ item.subtotal_amount ? parseFloat(item.subtotal_amount).toFixed(2) : '' }}</td>
                        <td>{{ item.vat_amount ? parseFloat(item.vat_amount).toFixed(2) : '' }}</td>
                        <td>{{  parseFloat((item.subtotal_amount ?? 0) + (item.vat_amount ?? 0)).toFixed(2) }}</td>
                        <td>{{ item.total_discount ? parseFloat(item.total_discount).toFixed(2) : '' }}</td>
                        <td>{{ item.paid_amount ? parseFloat(item.paid_amount).toFixed(2) : '' }}</td>
                        <td>{{ getTotalDue(item) ? parseFloat(getTotalDue(item)).toFixed(2) : '' }}</td>
                        <td>{{ item.stocks_sum_stock_value ? parseFloat(item.stocks_sum_stock_value).toFixed(2) : '' }}</td>
                        <td>{{ item.stocks_sum_profit_value ? parseFloat(item.stocks_sum_profit_value).toFixed(2) : '' }}</td>
                    </tr>
                    <tr v-if="currentPage === lastPage">
                      <td colspan="2"></td>
                      <td v-if="hasBusinessName"></td>
                      <td v-if="salePersonName"></td>
                      <td v-if="contactName"></td>
                      <td> {{ totalValue && totalValue.total_subtotal_amount }}</td>
                      <td> {{ totalValue && totalValue.total_vat_amount }}</td>
                      <td> {{ totalValue && totalValue.total_sum_amount }}</td>
                      <td> {{ totalValue && totalValue.total_discount_amount }}</td>
                      <td> {{ totalValue && totalValue.total_paid_amount }}</td>
                      <td> {{ totalValue && totalValue.total_due_amount }}</td>
                      <td> {{ totalValue && totalValue.total_stock_value }}</td>
                      <td> {{ totalValue && totalValue.total_profit_value }}</td>
                    </tr>
                </tbody>
            </table>
        <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>
</div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
    tableItems: {
        type: Array,
    },
    currentPage: Number,
    lastPage: Number,
    totalValue: Object,
})

const hasBusinessName = computed(() => {
  return props.tableItems.some(item => item.business_name && item.business_name.trim() !== '');
});

const salePersonName = computed(() => {
  return props.tableItems.some(item => item.sr_name && item.sr_name.trim() !== '');
});

const contactName = computed(() => {
  return props.tableItems.some(item => item.contact_name && item.contact_name.trim() !== '');
});

const getTotalDue = (item) => {
    return parseFloat(item.total_amount) - parseFloat(item.net_receipt_amount) - parseFloat(item.ait_deducted) - parseFloat(item.vat_deducted) - parseFloat(item.discount)
}
</script>

<style scoped>
.mr-5 {
    margin-right: 5px;
}
.action-th {
    width: 20%;
    text-align: center;
}
.name {
    width: 15%;
}
.action{
    text-align: center;
}

</style>